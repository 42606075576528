<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Real gases behave more like ideal gases as the volume is increased at constant temperature.
        What are the reasons for this? Select all that apply.
      </p>

      <div v-for="option in options" :key="option.value" class="pl-8">
        <v-checkbox
          v-model="inputs.input1"
          :value="option.value"
          :label="option.text"
          class="my-0 py-0"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'Question62',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {
          text: 'Since the molecules are farther apart, the interactions between individual gas molecules are less significant.',
          value: '1',
        },
        {
          text: 'At the decreased pressure, collisions between molecules of the gas are weaker.',
          value: '2',
        },
        {
          text: 'The size of the molecules is less significant with respect to the volume of the container',
          value: '3',
        },
      ],
    };
  },
};
</script>
